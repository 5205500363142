.category-card {
    transition: 2s;
    text-decoration: none;

    .product-category-card {
        height: 250px;
        background-position: center center;
        background-size: cover;
        border-radius: 20px;
        box-shadow: inset 0px -5px 200px rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: flex-end;
        justify-content: center;

        /*Hover animation missing*/
        .product-category-name {
            font-family: $ctaFont;
            font-size: 2em;
            font-weight: 600;
            color: #ffffff;
            padding-bottom: 10px;
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        }
    }
}

/*Hero*/
.hero-container {
    height: 500px;
    display: block;
    position: relative;
    background-image: url("../floral-bg.jpg");
    background-size: cover;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba($backgroundColor, 0.7);
    }
    .row {
        position: relative;
        .text-col {
            .text-container {
                background-color: rgba(255, 255, 255, 0.7);
                button {
                    padding-left: 3rem;
                    padding-right: 3rem;
                }
                a {
                    padding-left: 3rem;
                    padding-right: 3rem;
                }
            }
        }
        .slideshow-container {
            max-height: 500px;
            margin-right: -12px;
            * {
                height: 100%;
            }
            img {
                object-fit: cover;
                margin-left: auto;
                border-left: 2rem solid rgba(255, 255, 255, 0.7);
            }
        }
    }
}

.product-container {
    width: 100%;
    padding-top: 1em;
    background-color: #ffffff;

    .col {
        @include flexbox();
        padding-top: 2em;
        padding-bottom: 2em;
    }
}

.about-container {
    .about-row {
        padding-top: 6em;

        &:last-child {
            padding-bottom: 6em;
        }
    }
}

.newest-products-container {
    background-color: $backgroundColor;
    .newproducts-catalog {
        .carousel-inner {
            border-radius: 10px;
            .carousel-item {
                background-repeat: no-repeat;
                background-size: cover;
                > a {
                    backdrop-filter: blur(6px);
                }
                // &::after {
                //     content: "";
                //     background: linear-gradient(
                //         360deg,
                //         rgba(0, 0, 0, 0.56) 0%,
                //         rgba(0, 0, 0, 0) 50%
                //     );

                //     position: absolute;
                //     display: block;
                //     width: 100%;
                //     height: 100%;
                //     top: 0;
                //     left: 0;
                // }
                .carousel-caption {
                    z-index: 1;
                    padding-bottom: 0;
                    *:not(a) {
                        text-shadow: 1px 1px 10px black;
                    }
                }
            }
        }
        .carousel-control-prev,
        .carousel-control-next {
            filter: invert(0.7);
            text-shadow: 3px 3px 10px black;
        }
    }
}

@media screen and (min-width: $breakpoint-md) {
    .contact-modal {
        max-width: 50vw;
    }
    .hero-container {
        max-height: 70vh;
    }
}

@media screen and (max-width: $breakpoint-md) {
    .contact-modal {
        max-width: 60vw;
    }

    .hero-container {
        padding: 0px !important;
        height: auto;
        .row {
            margin: 0px;
            > * {
                padding: 0px;
            }
            .text-container {
                display: flex;
                justify-content: center;
                button {
                    padding-left: 2rem !important;
                    padding-right: 2rem !important;
                }
            }
            .slideshow-container {
                margin-right: 0px;
                img {
                    border-left: none !important;
                }
            }
        }
    }
    .product-container {
        .product-category-card {
            height: 170px;
        }
    }
}

@media screen and (max-width: $breakpoint-sm) {
    .contact-modal {
        max-width: 100vw;
    }
}
