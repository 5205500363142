$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

$ctaFont: "Open Sans", sans-serif;
$defaultFont: "PT Serif", serif;

$backgroundColor: rgb(255, 243, 243);
$primary: #e16075;
$secondary: #594157;
$primaryText: #1f1300;
$bodyText: #272b26;

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "dark": $primaryText,
);

// $productcard-width:
// $productcard-height:

*,
*:before,
*:after {
    box-sizing: border-box;
}

* {
    font-family: $defaultFont;
}

#root {
    min-height: 100vh;
    .hero-container > div {
        &:before {
            content: " ";
            background-image: url(../floral-bg.jpg);
            background-size: cover;
            opacity: 0.4;
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 100px;
            left: 0;
            z-index: -1;
        }
    }
}

.modal-open {
    padding-right: 0px !important;
}

@mixin flexbox {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin wrapperPadding {
    padding-top: 1em;
    padding-bottom: 150px;
}

@import "~bootstrap/scss/bootstrap.scss";
@import "./Home.scss";
@import "./Shop.scss";
@import "./ProductPage.scss";
@import "../components/Navbar/Search/Search.scss";

@keyframes slideInUp {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0%);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0%;
    }

    to {
        opacity: 100%;
    }
}
@keyframes fadeOut {
    from {
        opacity: 100%;
    }

    to {
        opacity: 0%;
    }
}

.hero-fade-in {
    animation-name: fadeIn;
    animation-duration: 1s;
}

.hero-fade-out {
    animation-name: fadeOut;
    animation-duration: 1s;
}

.btn {
    font-family: $ctaFont;
    color: #fff !important;
    &:focus {
        box-shadow: none !important;
    }
    a {
        text-decoration: none;
        color: inherit;
        font-family: inherit;
        &:hover {
            color: white;
        }
    }
}

.btn-outline-primary {
    color: $primary !important;
    &:hover {
        color: #fff !important;
    }
}

.btn-outline-secondary {
    color: $secondary !important;
}

.btn-outline-dark {
    color: $primaryText !important;
    &:focus {
        box-shadow: var(--bs-btn-focus-box-shadow) !important;
    }
}

h2 {
    font-size: 42px;
}

.header-text {
    @include flexbox();
}

@media only screen and (max-width: $breakpoint-md) {
    .search-results {
        width: 100% !important;
        right: 0px !important;
        }    
    .navbar-mobile-search-open {
        position: absolute !important;
        min-height: 100vh;
        overflow-y: scroll;
    }
}
@media only screen and (min-width: $breakpoint-md) {
    .navbar-search {
        width: 30vw;
        position: relative;
        .search-results {
            width: 100%;
        }
    }
}

@media only screen and (max-width: $breakpoint-md) {
    main.navbar-toggled {
        overflow-y: hidden;
    }
}

.nav-full {
    width: 100%;
    max-width: 100%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: white;

    .nav-container {
        padding: 10px;

        img {
            width: 100%;
            height: auto;
            max-width: 180px;
        }

        button {
            border: none;
        }

        .row {
            align-items: baseline;
        }

        .nav-item a {
            color: $bodyText;
        }
        .cart-mobile {
            display: none;
        }

        .cart-desktop {
            display: flex;
            .badge {
                vertical-align: top;
                border-radius: 1.5em !important;
                padding: 0.2em 0.3em 0.2em 0.3em;
                top: -5px;
                right: 5px;
            }
        }

        .search-results {
            position: absolute;
            background-color: white;
            .list-group {
                .list-group-item {
                    &.active {
                        background-color: #00000081;
                        border-color: #00000081;
                    }
                }
            }
        }
        .btn {
            background-color: white;
            color: $bodyText !important;
            &:hover {
                background-color: #f9fafb !important;
                border-color: #f9fafb !important;
            }
        }
    }
}

.footer {
    background-color: $bodyText;
    min-height: 130px;
    color: #f9fafb;
    a {
        color: #f9fafb;
    }
}

.order-container .input-group button {
    display: none;
}

@media screen and (max-width: $breakpoint-md) {
    .nav-container {
        .cart-desktop {
            display: none !important;
        }
        .cart-mobile {
            display: flex !important;
            .badge {
                vertical-align: top;
                border-radius: 1.5em !important;
                padding: 0.2em 0.3em 0.2em 0.3em;
                top: -5px;
                right: 5px;
            }
        }
    }
    .product-card-col {
        margin-bottom: 3rem;
    }
}

.form-group.required .form-label:after,
.form-group.required .form-check-label:after {
    content: "*";
    color: red;
}

.cart-container {
    .list-group-item {
        border-width: 1px;
        &.active {
            background-color: unset;
            border-color: rgba(0, 0, 0, 0.125);
            color: unset;
        }
    }
    .item-container {
        a {
            text-decoration: none;
        }
        .stepper-buttons button {
            width: 24px;
            min-width: 1px;
            border: none;
            padding: 0px;
        }
        .btn {
            background-color: rgba(0, 0, 0, 0);
            color: $bodyText !important;
            text-decoration: underline;
            border: none;
            &:hover {
                background-color: #f9fafb !important;
                border-color: #f9fafb !important;
            }
        }
    }
}

.purchase-container,
.contact-container {
    min-height: calc(100vh - 190px);
    background-color: rgba($backgroundColor, 0.7);
    background-size: cover;
    &:before {
        background-image: url("../floral-bg.jpg");
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        opacity: 0.6;
        max-width: 100vw;
    }

    .checkout-container,
    .order-container,
    .cart-list-container,
    .delivery-container,
    .confirmation-container,
    .contact-form-container {
        padding: 2rem;
        background-color: white;
        box-shadow: 0 0 5px 0 black;
        ul {
            padding-left: 0;
            li {
                display: block;
            }
        }
    }
}

.offcanvas-checkout-container {
    .checkout-item {
        .stepper-buttons button {
            width: 24px;
            min-width: 1px;
            border: none;
            padding: 0px;
        }
        .btn {
            background-color: rgba(0, 0, 0, 0);
            color: $bodyText !important;
            border: none;
            &:hover {
                background-color: #f9fafb !important;
                border-color: #f9fafb !important;
            }
        }
    }
}

.modal {
    max-width: 100vw;
}

.locker-modal {
    max-width: 100vw;
}

.toggle-selector-group.btn-group-vertical {
    width: 100%;
    .btn {
        border-radius: var(--bs-btn-border-radius) !important;
    }
    .btn-check:checked + .btn {
        background-color: unset;
        box-shadow: var(--bs-btn-focus-box-shadow) !important;
    }
}

@media only screen and (max-width: $breakpoint-md) {
    .locker-modal {
        width: 100vw;
        .modal-body {
            padding: 0;
            height: 100vh;
        }
    }
    .cart-list-remove-desktop {
        display: none;
    }
    .cart-list-remove-mobile {
        display: block;
    }
}

@media only screen and (min-width: $breakpoint-md) {
    .locker-modal {
        width: 75vw;
        .modal-body {
            padding: 0;
            height: 80vh;
        }
    }
    .cart-list-remove-desktop {
        display: block;
    }
    .cart-list-remove-mobile {
        display: none;
    }
}
@media only screen and (max-width: $breakpoint-md) {
    .locker-modal {
        .modal-body {
            padding: 0;
            height: 90vh;
        }
    }
}
