.navbar-search {
    .pagination {
        margin-bottom: 0;
        .page-item {
            width: 50%;
            &:last-child {
                .page-link {
                    border-left: 1px solid black
                }
            }
            .page-link {
                border: none;
                font-size: 1.25rem;
                &:hover, &:focus {
                    background-color: transparent
                }
            }
        }
    }
}