.category-picker {
    padding: 1em 0 1em 0;
}

.container {
    #shop {
        margin: 0px auto 0px auto;
    }
}

.shop-container {
    gap: 1em;
    padding: 50px 0px 50px 0px;
}

.sidebar-body {
    display: block !important;
    .sidebar-container {
        padding-bottom: 50px;
        padding-top: 50px;
        .sidebar-title {
            padding-left: 20px
        }
        .filter-items {
            overflow-x: hidden;
            overflow-y: auto;
            max-height: 8rem;
            padding-left: 0;
            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
                cursor: w-resize;
            }
            &::-webkit-scrollbar-track-piece {
                background-color: lightgray;
                border-radius: 5px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: gray;
                border-radius: 5px;
            }
        }
    }
}

.cart-item {
    padding-bottom: 1.5em;
    &:not(:first-child) {
        padding-top: 1.5em;
    }
    &:not(:last-child) {
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-image: linear-gradient(
                90deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.6) 50%,
                rgba(0, 0, 0, 0) 100%
            )
            1;
    }
    .cart-img-container {
        img {
            max-width: 75%;
        }
    }
}

.product-card-col {
    a {
        text-decoration: none;
        .card-wrapper {
            background-color: $secondary;
            border-radius: 10%;
            overflow: hidden;
            color: white;
            transition: all 0.2s ease-in-out;
            min-height: calc(100% - 2rem);

            &:hover {
                min-height: calc(100% - 2rem + 20px);
                transform: translateY(-10px);
            }
            .prod-img-wrapper {
                .prod-img {
                    width: 100%;
                    max-height: 146px;
                    object-fit: cover;
                }
            }
        }
    }
}

.sidebar-toggle {
    display: none;
}

@media screen and (max-width: $breakpoint-md) {
    .sidebar-toggle {
        color: $primary;
        display: block;
        position: sticky;
        top: 95px;
        background-color: white;
        border-bottom: 1px solid $bodyText;
        z-index: 100;
        svg * {
            color: $primary;
            stroke-linecap: round;
        }
    }
    .sidebar-title {
        display: none;
    }
    .sidebar-container {
        padding-top: 0px !important;
    }
}

