.prodimage-container {
    padding-left: 2em;

    .prodimage {
        border-radius: 5px;
        max-width: 80%;
    }
}

.product-right {
    font-size: 1.2em;
    .prodname-container {
        display: inline-block;
        border-bottom: 2px solid $bodyText;
        padding-bottom: 0.5em;
        .prodname {
            font-weight: 400;
        }
    }
    .property-list-container {
        padding-top: 0.5em;
        .price-container {
            span {
                font-size: 1.5rem;
            }
        }
        .property-container {
            &::first-letter {
                text-transform: capitalize;
            }
            a {
                text-decoration: none;
            }
            .property {
                padding-right: 0.8em;
            }
        }
    }
}
.similar-products-container {
    padding-top: 2em;

    .similar-products-list {
        padding-top: 1em;
    }
}

@media screen and (max-width: $breakpoint-md) {
    .product-right {
        padding-top: 1rem;
    }
}
